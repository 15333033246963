import { IContentAuthor } from '../../types'
import { AuthorPill } from 'component-library'
import MainTitle from '../MainTitle/MainTitle'
import Image from '../Image/Image'
import './style.scss'

interface IDemoHeroProps {
  title: string
  contentAuthors: IContentAuthor[]
  image?: string
  publicationDate: string
  modificationDate?: string
}

const DemoHero = ({
  title,
  contentAuthors,
  image,
  publicationDate,
  modificationDate,
}: IDemoHeroProps) => {
  const formatDemoDate = (date: string) => {
    const dateObj = new Date(date)
    return dateObj.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    })
  }

  return (
    <div className="DemoHero">
      {image && (
        <Image
          src={image}
          alt=""
          className="DemoHero__img"
          data-testid="demo-img"
        />
      )}
      <MainTitle title={title} addClass="mb-8" />

      <div className="flex flex-wrap gap-3 min-h-[28px]">
        {contentAuthors.map((author: IContentAuthor, index) => (
          <AuthorPill
            key={`author-${index}`}
            name={author?.name || ''}
            image={author?.image || ''}
            profileUrl={author?.profileUrl || ''}
            username={author?.username || ''}
            className="mr-6"
            size="md"
          />
        ))}
      </div>

      <p className="DemoHero__dates">
        Published: {formatDemoDate(publicationDate)}.{' '}
        {modificationDate &&
          `Last updated: ${formatDemoDate(modificationDate)}.`}
      </p>
    </div>
  )
}

export default DemoHero
